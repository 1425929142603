import React from "react";

import { useSelector } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";

import {
  selectStatus,
  selectFirstName,
  selectLastName,
  selectCourseTitle,
} from "./redux/formData";

import Pdf from "./components/Pdf/pdf";
import FormInput from "./components/formInput";
import FormCheck from "./components/formCheck";
import OptionButton from "./components/optionButton";
import { SimplifyDocument } from "./components/Pdf/documents/simplifyDocument";

/**
 * uncomment for preview
 * import { PDFViewer } from "@react-pdf/renderer";
 */

export default function App() {
  // --- STATE ---

  const status = useSelector(selectStatus);
  const courseTitle = useSelector(selectCourseTitle);
  const firstName = useSelector(selectFirstName);
  const lastName = useSelector(selectLastName);

  const [inputCourseTitle, setInputCourseTitle] = React.useState("");
  const [isCopiedShown, setIsCopiedShown] = React.useState(false);

  const isCopiedStyle = isCopiedShown ? "showCopied" : "";

  const iFrameTemplate = `<div style="position: relative; padding-bottom: 200%; background: white; padding-top: 0px; height: 0; overflow: auto; -webkit-overflow-scrolling: touch; border: none;"><center><iframe src="https://lp.pl-x.de/teilnahmebescheinigung_px_academy/pdl/?kurs=${inputCourseTitle
    .split(" ")
    .join(
      "_"
    )}" scrolling="yes" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; border: none; overflow: hidden;" ></iframe></center></div>`;

  // --- CALLBACK ---

  function handleChange(e) {
    const { value } = e.target;
    setInputCourseTitle(value);
  }

  function handleIsCopiedShown() {
    setIsCopiedShown(true);
    setTimeout(() => {
      setIsCopiedShown(false);
    }, 5000);
  }

  function getIdle() {
    if (
      courseTitle.includes("<NO_COURSE_PARAM>") ||
      courseTitle.includes("<SYNTAX_ERR_COURSE_PARAM>")
    ) {
      return (
        <>
          {courseTitle === "<NO_COURSE_PARAM>" && (
            <h1 className="course--param--title">
              <span className="err">ACHTUNG: </span>
              Du hast vergessen den Kurs als Parameter in der iFrame URL
              anzugeben!
            </h1>
          )}
          {courseTitle === "<SYNTAX_ERR_COURSE_PARAM>" && (
            <>
              <h1 className="course--param--title">
                <span className="err">ACHTUNG:</span> Du hast den Kurs scheinbar
                nicht korrekt als Parameter in der iFrame URL angegeben!
              </h1>
            </>
          )}
          <p className="text course--param--text">
            Um den Kurs korrekt als Parameter in die iFrame URL einzufügen
            kannst du den Kurs in das folgende Formularfeld eintragen und das
            darunterstehende iFrame kopieren, indem du in die Box klickst.
          </p>
          <p className="text course--param--text--margin--top course--param--text">
            <span className="bold">HINWEIS</span>: Das was du als Kurs angibst
            wird später als Kurs auf der Teilnahmebescheinigung zu sehen sein.
          </p>

          <input
            name="firstName"
            placeholder="Hier Kurs eintragen"
            className="form--input box course--param--input"
            value={inputCourseTitle}
            onChange={handleChange}
          />
          <CopyToClipboard text={iFrameTemplate}>
            <div className="box iframe_explain" onClick={handleIsCopiedShown}>
              <p className={`${isCopiedStyle} copied--text`}>Kopiert</p>
              <p>{iFrameTemplate}</p>
            </div>
          </CopyToClipboard>
        </>
      );
    } else {
      return <FormInput />;
    }
  }

  // --- RENDER ---

  return (
    /**
     * uncomment for preview
     *  <PDFViewer>
     *     <SimplifyDocument
     *       firstName={firstName}
     *       lastName={lastName}
     *       courseTitle={courseTitle.split("_").join(" ")}/>
     *  </PDFViewer>
     *
     *  */

    <div className="main container">
      {status === "idle" && getIdle()}
      {status === "filled" && (
        <>
          <FormCheck />
          <OptionButton />
        </>
      )}
      {status === "download" && (
        <div>
          <Pdf>
            <SimplifyDocument
              firstName={firstName}
              lastName={lastName}
              courseTitle={courseTitle.split("_").join(" ")}
            />
          </Pdf>
        </div>
      )}
    </div>
  );
}
