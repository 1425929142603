import React from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { useSelector } from "react-redux";
import "moment/locale/de";

import { selectCourseTitle } from "../../redux/formData";

function Pdf({ children }) {
  // --- STATE ---

  const courseTitle = useSelector(selectCourseTitle);

  // --- RENDER ---

  return (
    <>
      <h1>Hier können Sie die Teilnahmebescheinigung herunterladen:</h1>
      <PDFDownloadLink
        className="download box"
        document={children}
        fileName={courseTitle.toLowerCase()}
      >
        {({ loading }) => (loading ? "Einen Moment..." : "Jetzt herunterladen")}
      </PDFDownloadLink>
    </>
  );
}

export default Pdf;
