import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { selectFirstName } from "../redux/formData";
import { selectLastName } from "../redux/formData";

import { changeFirstName } from "../redux/formData";
import { changeLastName } from "../redux/formData";
import { changeStatus } from "../redux/formData";

export default function FormInput() {
  // --- STATE ---
  const dispatch = useDispatch();

  let firstName = useSelector(selectFirstName);
  let lastName = useSelector(selectLastName);

  const [errorFirstName, setErrorFirstName] = React.useState(false);
  const [errorLastName, setErrorLastName] = React.useState(false);

  const errorStyleFirstName = errorFirstName
    ? { border: "1px solid #FF3333" }
    : { border: "1px solid transparent" };

  const errorStyleLastName = errorLastName
    ? { border: "1px solid #FF3333" }
    : { border: "1px solid transparent" };

  // --- CALLBACK ---

  function changeHandler(e) {
    let { name, value } = e.target;
    if (name === "firstName") {
      setErrorFirstName(false);
      dispatch(changeFirstName(value));
    } else {
      setErrorLastName(false);
      dispatch(changeLastName(value));
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (firstName && lastName) {
      dispatch(changeStatus("filled"));
    } else if (!firstName && !lastName) {
      setErrorFirstName(true);
      setErrorLastName(true);
    } else if (firstName) {
      setErrorLastName(true);
    } else {
      setErrorFirstName(true);
    }
  }

  // --- RENDER ---

  return (
    <>
      <h1>Bitte tragen Sie hier Ihre Daten ein</h1>
      <form>
        <div className="form--element">
          <input
            style={errorStyleFirstName}
            onChange={changeHandler}
            value={firstName}
            name="firstName"
            placeholder="Vorname"
            className="form--input box"
          />
        </div>
        <div className="form--element">
          <input
            style={errorStyleLastName}
            onChange={changeHandler}
            value={lastName}
            name="lastName"
            placeholder="Nachname"
            className="form--input form--input--last box"
          />
        </div>
        <button className="form--submit box" onClick={handleSubmit}>
          Weiter
        </button>
      </form>
    </>
  );
}
