import React from "react";
import { useSelector } from "react-redux";

import { selectFirstName } from "../redux/formData";
import { selectLastName } from "../redux/formData";

export default function FormCheck() {
  // --- STATE ---

  const firstName = useSelector(selectFirstName);
  const lastName = useSelector(selectLastName);

  // --- RENDER ---

  return (
    <>
      <h1>Sind Ihre Angaben so korrekt?</h1>
      <p className="text">
        Wir erstellen Ihre Teilnahmebescheinigung mit den folgenden Angaben:
      </p>
      <div className="check--element box">
        <p className="check--text">
          Vorname: <span className="bold">{firstName}</span>
        </p>
        <p className="check--text">
          Nachname: <span className="bold">{lastName}</span>
        </p>
      </div>
    </>
  );
}
