import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { changeStatus } from "../redux/formData";

import { selectFirstName } from "../redux/formData";
import { selectLastName } from "../redux/formData";
import { selectCourseTitle } from "../redux/formData";

export default function OptionButton() {
  // --- STATE ---

  const dispatch = useDispatch();

  const firstName = useSelector(selectFirstName);
  const lastName = useSelector(selectLastName);
  const courseTitle = useSelector(selectCourseTitle);

  // --- CALLBACK ---

  function handleDownload() {
    localStorage.setItem(
      `${courseTitle.toLowerCase()}`,
      JSON.stringify({ firstName: firstName, lastName: lastName })
    );
    dispatch(changeStatus("download"));
  }

  // --- RENDER ---

  return (
    <div className="button--box box">
      <button
        className="button--option button--back"
        onClick={() => dispatch(changeStatus("idle"))}
      >
        Zurück
      </button>
      <button
        className="button--option button--further"
        onClick={handleDownload}
      >
        Weiter
      </button>
    </div>
  );
}
