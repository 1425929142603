import { createSlice } from "@reduxjs/toolkit";

const urlParam = new URLSearchParams(window.location.search);
const courseParam = urlParam.get("kurs");

function checkCourseParam() {
  if (courseParam) {
    if (
      courseParam.includes("_") &&
      !courseParam.includes(" ") &&
      !courseParam.includes("-") &&
      !courseParam.includes(".")
    ) {
      return courseParam;
    } else if (
      courseParam.includes(" ") ||
      courseParam.includes("-") ||
      courseParam.includes(".")
    ) {
      return "<SYNTAX_ERR_COURSE_PARAM>";
    } else {
      return courseParam;
    }
  } else {
    return "<NO_COURSE_PARAM>";
  }
}

const currentLocalStorage = JSON.parse(
  localStorage.getItem(checkCourseParam().toLowerCase())
);

const formDataSlice = createSlice({
  name: "formData",
  initialState: {
    firstName: currentLocalStorage ? currentLocalStorage.firstName : "",
    lastName: currentLocalStorage ? currentLocalStorage.lastName : "",
    status: currentLocalStorage ? "download" : "idle",
    courseTitle: checkCourseParam(),
  },
  reducers: {
    changeFirstName: (state, action) => {
      state.firstName = action.payload;
    },
    changeLastName: (state, action) => {
      state.lastName = action.payload;
    },
    changeStatus: (state, action) => {
      state.status = action.payload;
    },
    setcourseTitle: (state, action) => {
      state.courseTitle = action.payload;
    },
  },
});

export const { changeFirstName } = formDataSlice.actions;
export const { changeLastName } = formDataSlice.actions;
export const { changeStatus } = formDataSlice.actions;
export const { setCourseTitle } = formDataSlice.actions;

export const selectFirstName = (state) => state.formData.firstName;
export const selectLastName = (state) => state.formData.lastName;
export const selectStatus = (state) => state.formData.status;
export const selectCourseTitle = (state) => state.formData.courseTitle;

export default formDataSlice.reducer;
