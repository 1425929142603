import React from 'react';
import { createRoot } from 'react-dom/client';
import {Provider} from "react-redux"

import {store} from "./redux/index"

import './index.css';

import App from './App';

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(
<Provider store={store}>
  <App tab="home" />  
</Provider>
);

/* import React from 'react';
import ReactDOM from 'react-dom';
import { PDFViewer } from '@react-pdf/renderer';
import {Provider} from "react-redux"
import {store} from "./redux/index"

import PDF_pdl from "./components/PDF_pdl"

const App = () => (
  <PDFViewer>
    <Provider store={store}>
      <PDF_pdl />
    </Provider>
  </PDFViewer>
);

ReactDOM.render(<App />, document.getElementById('root')); */