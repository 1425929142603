import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
  Note,
} from "@react-pdf/renderer";

import "moment/locale/de";

import CalibriRegular from "../../../fonts/calibri-regular.ttf";
import CalibriBold from "../../../fonts/calibri-bold.ttf";

import simplifyLogo from "../../../images/simplify_kurs_logo.png";

import simplifyAkademie from "../../../images/simplify_akademie.jpg";
import simplifySinature from "../../../images/simplify_signature.jpg";

Font.register({
  family: "Calibri",
  fonts: [
    {
      src: CalibriRegular,
    },
    {
      src: CalibriBold,
      fontWeight: "bold",
    },
  ],
});

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "white",
    color: "#1c1c1c",
    padding: 50,
    paddingTop: 30,
    paddingBottom: 30,
    position: "relative",
  },
  image_logo: {
    position: "absolute",
    top: -30,
    left: -50,
    right: -50,
  },
  heading: {
    fontSize: "38",
    textTransform: "uppercase",
    fontFamily: "Calibri",
    fontWeight: 700,
  },
  name: {
    marginTop: 35,
    display: "inline-block",
  },
  highlight_bg: {
    backgroundColor: "#ffff54",
  },
  image_rbplogo: { width: "100px", margin: "0 auto", marginTop: 20 },
  image_signature: {
    width: 130,
    display: "block",
    objectFit: "contain",
    marginTop: 10,
    marginBottom: 10,
  },
  view_center: {
    textAlign: "center",
    paddingTop: 220,
    width: 450,
    margin: "0 auto",
  },
  view_left: { textAlign: "left" },

  title: { fontSize: "25" },
  flex: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  footer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    marginTop: 20,
    padding: 50,
    paddingBottom: 30,
  },
  body_font_size: { fontSize: 16, fontFamily: "Calibri" },
  highlight_font: { fontFamily: "Calibri", fontSize: 24, fontWeight: 700 },
  highlight_font_reg: { fontFamily: "Calibri", fontSize: 22 },
});

const date = new Date().toLocaleDateString("de-DE", {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
});

// Create Document Component
export const SimplifyDocument = ({ firstName, lastName, courseTitle }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View>
        <Image style={styles.image_logo} src={simplifyLogo} />
      </View>
      <View style={styles.view_center}>
        <Text style={styles.heading}>TEILNAHMEBESTÄTIGUNG</Text>
        <Text style={[styles.highlight_font_reg, styles.name]}>
          <Note style={styles.highlight_bg}>
            {firstName} {lastName}
          </Note>
        </Text>
        <Text style={[styles.highlight_font_reg, { marginTop: 50 }]}>
          hat erfolgreich an unserem simplify® Online-Kurs mit einer Dauer von{" "}
          <Note style={styles.highlight_bg}>8 Wochen</Note> teilgenommen.
        </Text>
        <Text
          style={[styles.highlight_font, { marginTop: 40, color: "#f1bc40" }]}
        >
          <Note style={styles.highlight_bg}>{courseTitle}</Note>
        </Text>
      </View>
      <View style={[styles.flex, { marginTop: 80 }]}>
        <View>
          <Image style={styles.image_signature} src={simplifySinature}></Image>
          <Text
            style={[
              styles.highlight_font,
              { fontSize: 12, marginBottom: 8, letterSpacing: 0.4 },
            ]}
          >
            Debora Karsch
          </Text>
          <Text
            style={[
              styles.highlight_font,
              { fontSize: 12, marginBottom: 8, letterSpacing: 0.4 },
            ]}
          >
            Kursleiterin simplify® Akademie
          </Text>
          <Text
            style={[
              styles.highlight_font,
              { fontSize: 12, fontWeight: 400, letterSpacing: 0.4 },
            ]}
          >
            Bonn, den <Note style={styles.highlight_bg}>{date}</Note>
          </Text>
        </View>
        <Image
          style={{ marginBottom: 10, width: 180 }}
          src={simplifyAkademie}
        ></Image>
      </View>
      <View style={styles.footer}>
        <Text style={[styles.body_font_size, { fontSize: 11 }]}>
          simplify® - ein Unternehmensbereich der VNR Verlag für die Deutsche
          Wirtschaft AG, Sitz: Bonn, Amtsgericht Bonn, HRB8165, Vorstand:
          Richard Rentrop
        </Text>
      </View>
    </Page>
  </Document>
);
